import { prop } from '@/helpers'
import { CasinoInfo } from '../CasinoInfo'

export const getCardId = prop(CasinoInfo.cardId)
export const getCardExpiryDate = prop(CasinoInfo.cardExpiryDate)
export const getCardName = prop(CasinoInfo.cardName)
export const getCardLevel = prop(CasinoInfo.cardLevel)
export const getFirstName = prop(CasinoInfo.firstName)
export const getLastName = prop(CasinoInfo.lastName)
export const getAccountBalance = prop(CasinoInfo.accountBalance)
export const getAccountBonusMoney = prop(CasinoInfo.accountBonusMoney)
export const getAccountRealMoney = prop(CasinoInfo.accountRealMoney)
export const getPlatinCoins = prop(CasinoInfo.platinCoins)
export const getSession = prop(CasinoInfo.session)
export const getAccountWithdrawableMoney = prop(
  CasinoInfo.accountWithdrawableMoney,
)
export const getAccountNonWithdrawableMoney = prop(
  CasinoInfo.accountNonWithdrawableMoney,
)
export const getBonusInfo = prop(CasinoInfo.bonusInfo)
